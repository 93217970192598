$primary-color: #39ff14;
$secondary-text-color: #4CAF50;
$secondary-color: #333;
$highlight-color: #61dbfb;
$background-color: #1e1e1e;
$shadow-color: rgba(0, 0, 0, 0.6);
$dark-bg: rgba(0, 0, 0, 0.6);
$white: #fff;

header {
  background: linear-gradient(to right, #333333, #555555);
  color: $white;
  padding: 1.5rem;
  text-align: center;
  font-size: 1.2em;
  border: 1px solid $primary-color;
  
}
header h1 {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.theme-icon {
  font-size: 1rem;
  cursor: pointer;
  position: relative;
  background-color: $white
}
.theme-toggle-button{
  animation: unset;
  background-color: $white;
  border-color: $white
}
@keyframes ripple-matrix {
    0% {
      box-shadow: 0 0 20px $primary-color, 0 0 15px $primary-color, 0 0 20px $primary-color;
    }
    50% {
      box-shadow: 0 0 10px $primary-color, 0 0 10px $primary-color, 0 0 20px $primary-color;
    }
    100% {
      box-shadow: 0 0 10px $primary-color, 0 0 0px $primary-color, 0 0 0px $primary-color;
    }
  }

/* Profile summary and roles starts */

.story-profile {
  display: flex;
  flex-direction: row;
}

section{
  margin-right: 1rem;
}

.profile-summary {
  width: 100%;
  padding: 1.5rem;
  background: linear-gradient(to right, #333333, #555555);
  box-shadow: 0 2px 5px $shadow-color;
  border-radius: 8px;
  margin: 0 1rem;
  text-align: center;
  font-size: 1.1em;
  color: $white;
  line-height: 1.5;
  height: auto;
  overflow: hidden;
}

#roles-responsibilities {
  width: 50%;
  padding: 2rem;
  background: linear-gradient(to right, #333333, #555555);
  border-radius: 8px;
}

#roles-responsibilities ul{
  list-style-type: none;
  padding: 0;
}

#roles-responsibilities ul li i {
  margin-right: 5px;
  color: $highlight-color
}

#roles-responsibilities ul li{
  margin-bottom: 2rem;
}

.skills-roles{
  display: flex;
  flex-direction: row;
}

/* Profile summary and roles end */

/* Skills Section */

#skills {
  width: 50%;
  padding: 2rem;
  background-color: #2a2a2a;
  color: #fff;
  border-radius: 10px;
  text-align: center;
}

#skills h2 {
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
  color: #e0e0e0;
}

.skills-table {
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  border-collapse: collapse;
  color: #e0e0e0;
}

.skills-table th, .skills-table td {
  padding: 1rem;
  border-bottom: 1px solid #444;
  text-align: left;
}

.skills-table th {
  font-weight: 600;
  font-size: 1rem;
  color: #b0b0b0;
}

.skills-table .skill-name {
  display: flex;
  align-items: center;
}

.proficiency-bar {
  padding: 0.3rem 0.7rem;
  border-radius: 5px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  display: inline-block;
  width: 100px;
}

.proficiency-bar.expert {
  background-color: #4caf50; /* Green */
}

.proficiency-bar.advanced {
  background-color: #2196f3; /* Blue */
}

.proficiency-bar.intermediate {
  background-color: #ff9800; /* Orange */
}

/* Projects code starts */
.content-header {
  text-align: center;
  padding: 1rem 0;
  border-bottom: 2px solid #555;
  margin-bottom: 2rem;
}

.projects-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.project {
  background: linear-gradient(to right, #333333, #555555);
  border: 1px solid $secondary-text-color;
  border-radius: 8px;
  margin: 1rem;
  padding: 1rem;
  width: calc(30% - 2rem);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  transition: transform 0.2s;
  position: relative;
}

.project:hover {
  transform: scale(1.05);
}

.project h3 {
  color: $secondary-text-color;
  font-size: 1.5em;
}

.project p {
  font-size: 0.9em;
}

.key-learnings {
  background-color: #2a2a2a;
  padding: 10px;
  border-radius: 4px;
  margin-top: 10px;
}

.key-learnings h4 {
  margin: 0;
  color: $secondary-text-color;
  font-weight: bold;
}

.key-learnings p {
  margin: 5px 0;
}
.key-learnings a {
  color: #2196f3;
  cursor: pointer;
  text-decoration: underline;
}

/* Projects code ends */

/* Contact form code */
#contact {
  background: linear-gradient(to right, #333333, #555555);
  padding: 2rem;
  border-radius: 8px;
  width: 60%;
  margin: 0 auto;
}

form {
  display: flex;
  flex-direction: column;
}

form label {
  margin: 0.5rem 0 0.2rem;
  color: #e0e0e0;
}

form input,
form textarea {
  padding: 1rem;
  margin: 0.5rem 0;
  border-radius: 5px;
  background: #444;
  border: 1px solid #333;
  color: #fff;
}

form button {
  padding: 1rem;
  background-color: #39ff14;
  border: none;
  color: #fff;
  font-size: 1.2em;
  cursor: pointer;
  border-radius: 5px;
}

form button:hover {
  background-color: #4caf50;
}

/* Contact form code ends */

/* Theme based on bw and matrix */
.bw-theme {
  header {
    background: unset;
    background-color: $dark-bg;
    color: $white;
    border: 1px solid $dark-bg;
  }
  .primary-color {
    color: $white;
  }
  .slider-container .snap-label.active {
    background-color: $white;
  }
  .slider-container .snap-label {
    color: $dark-bg;
  }
  .project {
    h3,
    .key-learnings h4 {
      color: $white;
    }
    border: 1px solid $white;
  }
  .theme-icon {
    font-size: 1rem;
    cursor: pointer;
    position: relative;
    background-color: $dark-bg;
    color: $white;
  }
  .theme-toggle-button{
    animation: ripple-matrix 1.5s infinite ease-out;
    background-color: $dark-bg;
    border-color: $primary-color;
  }
}
@media (max-width: 768px) { 
  .content-header-sm {
    h2{
      margin: unset !important;
      margin-bottom: 5px !important;
    }
    text-align: center;
    border-bottom: 2px solid #555;
    margin-bottom: unset;
    color: white;
  }
  .content-header {
    display: none;
  }
  #skills, #roles-responsibilities{
    width: auto;
  }
  #skills{
    padding: 1rem 0;
  }
  .skills-roles{
    flex-direction: column;
    gap: 10px;
    padding-left: 1rem;
  }
  .content-container .content-section{
    padding: 20px 0 !important;
  }
  .projects-container{
    display: flex;
    flex-direction: column;
    gap:10px;
  }
  .projects-container .project{
    width: auto;
    margin: 1rem 0 1rem 1rem;
  }
  header{
    padding: 0;
  }
  body{
    overflow: scroll;
  }
  .theme-toggle-button{ 
    animation: unset;
  }
  .slider-wrapper {
    gap: unset !important;
  }
  .slider-container{
    height: unset !important;
  }
}